<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-edit"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      hide-footer
      hide-header
      size="sm"
    >
      <div>
        <div class="title mt-2">
          <h4>Edit Kits</h4>
        </div>
        <validation-observer ref="editFields">
          <b-form class="mt-2">
            <div class="mt-4">
              <b-form-group
                :label="$t('Ethnicity')"
              >
                <div class="lab-dropdown">
                  <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownEthnicity">
                    <span class="lab-dropdown-btn-value">{{ ethnicityName }}</span>
                    <span class="lab-dropdown-btn-icon">
                      <img :src="'https://api.illustrativedna.com/assets/icons/'+$store.state.appConfig.layout.skin+'/dropdown.svg'">
                    </span>
                  </div>
                  <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown }">
                    <ul>
                      <li v-for="ethnicityItem in ethnicityList" 
                        :key="ethnicityItem.id" 
                        @click="onSelectEthnicity(ethnicityItem.id)">
                        {{ ethnicityItem.ethnicName }}
                      </li>
                    </ul>
                  </div>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('Name')"
              >
                <validation-provider #default="{ errors }" rules="required" name="resultTitle">
                  <b-form-input
                    id="resultTitle"
                    :state="errors.length > 0 ? false:null"
                    v-model="modalObject.item.resultTitle"
                    :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="text-right mt-2">
              <button class="lab-btn lab-btn-lg mr-1 lab-btn-light" @click="hiddenModal()"> Close </button>
              <button class="lab-btn lab-btn-lg lab-btn-info" type="submit" @click.prevent="handleOk"> Save </button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service';

import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      isShowDropdown: false,
      ethnicityList: [],
    }
  },
  methods: {
    initialModal() {
      this.$refs['modal-edit'].show();
    },
    handleOk() {
      const kit = this.modalObject.item;

      this.$refs.editFields.validate().then(success => {
        if (success) {
          ApiService.post('order/kits/update', kit, this)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });

                this.modalObject.onSuccess();
                this.hiddenModal();
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
       });
    },
    hiddenModal() {
      this.$refs['modal-edit'].hide()
      this.modalObject.showModal = false;
    },

    onLoadEthnicities() {
      ApiService.get('Ethnicity', { dataVersion: this.modalObject.item.dataVersion }, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.ethnicityList = response.result;
          } else {
            AlertService.error(this._vm, response.message)
          }
        });
    },

    onSelectEthnicity(itemId) {
      this.modalObject.item.presetEthnicityId = itemId;
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownEthnicity;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    ethnicityName() {
      const ethnicity = this.ethnicityList.find(x => x.id === this.modalObject.item.presetEthnicityId);
      return ethnicity ? ethnicity.ethnicName : '';
    },
  },
  mounted() {
    this.initialModal();
    this.onLoadEthnicities();
  },  
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.past-coordinates-textarea {
  font-size: 0.8rem;
  border: none;
  background-color: var(--lab-light-house);
}
.past-coordinates-textarea:focus {
  border: 2px solid var(--lab-minted-elegance-color);
  box-shadow: none !important;
}
</style>
